import React, { useEffect } from "react";
import { jsx } from "theme-ui";
import { motion, useAnimation } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";

/** @jsx jsx */

const ImageWithOverlay = React.forwardRef(
  (
    {
      children,
      image,
      alt,
      direction = "left",
      deltaImg = "0%",
      isAnimated = false,
      overlayColor = "white",
      duration = 1,
      ...props
    },
    ref
  ) => {
    const controls = useAnimation();

    const getTransforms = () => {
      switch (direction) {
        case "top":
          return {
            overlay: {
              start: "translateY(0%)",
              end: "translateY(100%)",
            },
            img: {
              start: `translateY(-${deltaImg})`,
              end: "translateY(0%)",
            },
          };
        case "bottom":
          return {
            overlay: {
              start: "translateY(0%)",
              end: "translateY(-100%)",
            },
            img: {
              start: `translateY(${deltaImg})`,
              end: "translateY(0%)",
            },
          };
        case "left":
          return {
            overlay: {
              start: "translateX(0%)",
              end: "translateX(-100%)",
            },
            img: {
              start: `translateX(-${deltaImg})`,
              end: "translateX(0%)",
            },
          };
        case "right":
          return {
            overlay: {
              start: "translateX(0%)",
              end: "translateX(100%)",
            },
            img: {
              start: `translateX(${deltaImg})`,
              end: "translateX(0%)",
            },
          };
        default:
          throw new Error("direction not valide");
      }
    };

    useEffect(() => {
      if (isAnimated) {
        controls.start("visible");
      }
    }, [controls, isAnimated]);

    const variants = {
      visible: {
        transform: getTransforms().img.end,
      },
      hidden: {
        transform: getTransforms().img.start,
      },
    };

    return (
      <motion.div
        ref={ref}
        sx={{
          overflow: "hidden",
          position: "relative",
          display: "inline-block",
          "&::before": {
            content: '""',
            backgroundColor: overlayColor,
            position: "absolute",
            width: "100%",
            height: "calc(100% + 1px)",
            left: 0,
            top: 0,
            zIndex: 2,
            transition: `transform ${duration + 0.5}s`,
            transform: isAnimated
              ? getTransforms().overlay.end
              : getTransforms().overlay.start,
            willChange: "transform",
          },
        }}
      >
        <motion.div
          animate={controls}
          variants={variants}
          initial="hidden"
          transition={{ duration, ease: [0.23, 1, 0.32, 1] }}
          sx={{ maxWidth: "100%", height: "auto", display: "block" }}
          {...props}
        >
          <GatsbyImage image={image} alt={alt} />
        </motion.div>
      </motion.div>
    );
  }
);

export default ImageWithOverlay;
